import { Footer } from "@template/components";
import { StoreLinks } from "@template/components/StoreLinks";
import Head from "next/head";
import React from "react";

const HomePage: React.FC = () => {
  return (
    <div className="text-center flex flex-1 min-h-screen items-center justify-center flex-col relative bg-gradient-to-r bg-orange-100">
      <Head>
        <title>Orange</title>
        <meta
          name="description"
          content="Orange is an experimental social platform attempting to re-write the rules of social media."
        />
      </Head>

      <div className="mx-auto max-w-7xl p-4 sm:p-6 md:p-12 lg:p-16 xl:p-20 z-10 bg-orange-50 min-h-full">
        {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
        <div className="mx-auto max-w-2xl">
          <div className="display-flex flex-1 text-lg lg:text-xl xl:text-xl text-left text-black">
            <h1 className="text-xl lg:text-2xl xl:text-3xl pb-4 lg:pb-6 xl:pb-8 font-bold font-sans">
              What would social media look like if it were built for people, not profit?
            </h1>
            <p className="pb-4 leading-tight">
              Orange is an experimental social platform attempting to re-write the rules of social
              media. In some ways, its similar to a lot of other platforms you’re familiar with: a
              place to post your photos, with a social element to keep in touch with friends and
              family.
            </p>

            <p className="pb-4 leading-tight">
              It’s also not like those platforms at all. There’s no feed to catch up on, no “liking”
              involved, and no expectation of how to post a photo or when. You also won’t find any
              distractions with ads, influencers, pop-ups, or even suggestions of who to follow.
              You’re never going to be able to shop your friends’ photos.
            </p>

            <p className="pb-4 leading-tight">
              Everything you see on Orange is made by people you know. You can have up to 100
              friends on here, and each of them will follow you back. Don’t worry about amassing a
              ton of followers— Orange works great with a close-knit network of your friends and
              family!
            </p>
            <p className="pb-4 leading-tight">
              Sunset photo? That’s an orange. Cute dog on the street? Orange. Five vacation photos
              in a row? Absolutely right for Orange. Weekly documentation of your quest to try every
              bagel in town? No better place. Use it as a photo journal, or to keep in touch with
              friends.
            </p>

            <p className="pb-4 leading-tight">
              As much or as little as you share, Orange is designed to be spam-free. By removing all
              the junk that makes algorithm-driven platforms exploitative and fomo-inducing, Orange
              imagines an online space meant to deepen and enhance your existing connections and
              communities.
            </p>

            <p className="pb-4 leading-tight">
              Orange firmly refuses to analyze, sell, or snoop your data. Seriously— we do not want
              your data.
            </p>
            <p className=" pb-4">Orange is built to prioritize people, not profit.</p>
          </div>
        </div>

        <StoreLinks />

        <div className=" text-lg lg:text-xl xl:2xl text-gray-700 font-ApfelGrotezk pt-4 -mb-6">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
