import Image from "next/image";

import orangePic from "../public/images/orange.png";

const Footer = () => (
  <footer>
    <p className=" leading-5   md:order-1 text-center items-center flex-row block sm:flex justify-center text-black mb-6 md:mb-0">
      <span className="block md:inline-block">&copy; 2023 Propagation Applications, LLC</span>
      <span className="flex md:inline-block px-4 py-2 mt-0.5 items-center justify-center">
        <Image
          src={orangePic}
          style={{
            height: 24,
            width: 24,
          }}
          alt="an orange"
        />
      </span>
      <span className="block md:inline-block">Made with love in Fremont, WA</span>
    </p>
  </footer>
);

export { Footer };
