import Image from "next/image";
import React from "react";
import appStoreBadge from "../public/images/app-store-badge_resized.png";
import googlePlayBadge from "../public/images/google-play-badge_resized.png";

const badge_dimensions = {
  apple: {
    width: 2308,
    height: 772,
    aspect: 2308 / 772,
  },
  google: {
    height: 168,
    width: 564,
    aspect: 564 / 168,
  },
};

const BADGE_HEIGHT = 60;

export const StoreLinks: React.FC = () => {
  return (
    <div className="flex flex-row justify-center  gap-8 pt-4 pb-8">
      {/* App Store Badge: */}
      <div className="flex flex-col">
        <a href="https://apps.apple.com/us/app/orange/id6449561233">
          <Image
            src={appStoreBadge}
            alt="App Store Link"
            style={{ height: BADGE_HEIGHT, width: BADGE_HEIGHT * badge_dimensions.apple.aspect }}
          />
        </a>
      </div>

      <div className="flex flex-col">
        <a href="https://play.google.com/apps/testing/com.propagationapplications.nobs">
          <Image
            src={googlePlayBadge}
            alt="Google Play Link"
            style={{ height: BADGE_HEIGHT, width: BADGE_HEIGHT * badge_dimensions.google.aspect }}
          />
        </a>
      </div>
    </div>
  );
};
